<template>
  <vue-modal @close="$emit('close')" ref="modal" size="md">
    <template #title>Delete User</template>
    <template #body>
      <form @submit.prevent="goToDeleteMe">
          <div class="card-body">
            <FormText
              label="Password"
              password
              v-model="formDeleted.password"
            />
            <FormText
              label="Repetir Password"
              password
              v-model="formDeleted.password_confirmation"
            />
          </div>
        </form>
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="goToDeleteMe" type="submit">
        Eliminar Cuenta
      </button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
export default {
  components: {
    FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
     formDeleted: {
      password: null,
      password_confirmation: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
     goToDeleteMe() {
      const data = {
        password: this.formDeleted.password,
        password_confirmation: this.formDeleted.password_confirmation,
      };
      this.$store.dispatch("deleteMyUser",data)
      .then(() => {
       this.close();
      })
       
    },
  },
};
</script>
<style></style>
