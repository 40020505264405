<template>
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="col-md-6">
      <div class="card">
        <div class="text-center"><button @click="deleteUser" class="btn btn-danger float-right m-1" ><i class="fa-solid fa-trash"></i></button></div>
        <div class="card-header">
         <h4><i class="fa-solid fa-house-chimney-user"></i> My Account</h4>
        </div>
          <div class="card-body">
            <FormText
        label="Nombre"
        icon="fa-solid fa-user"
        v-model="item.first_name"
        disabled
      />
      <FormText
        label="Apellido"
        icon="fa-solid fa-user"
        v-model="item.first_name"
        disabled
      />
      <FormText
        label="Email"
        icon="fa-solid fa-envelope"
        v-model="item.email"
        disabled
      />
      <FormText
        label="Rol"
        icon="fa-solid fa-paragraph"
        v-model="item.role_name"
        disabled
      />
          </div>
      </div>
      <FormDeletedUserModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
    />
      <div class="card mt-3">
        <div class="card-header">
          <h4><i class="fa-solid fa-unlock-keyhole"></i> Cambiar Password</h4>
        </div>
        <form @submit.prevent="savePassword">
          <div class="card-body">
            <FormText
              label="Nueva Password"
              password
              v-model="form.new_password"
            />
            <FormText
              label="Repetir Nueva Password"
              password
              v-model="form.new_password_confirmation"
            />
            <FormText
              label="Password Actual"
              password
              v-model="form.password"
            />
          </div>
          <div class="card-footer">
            <button class="btn btn-primary btn-block" type="submit">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/FormText.vue";
import FormDeletedUserModal from "./FormDeletedUserModal.vue"

export default {
  components: {
    FormText,
    FormDeletedUserModal
  },
  data: () => ({
    form: {
      new_password: null,
      new_password_confirmation: null,
      password: null,
    },
    showModal: false,
    selItem: null,
  }),
  computed: {
    item() {
    if(this.$store.getters.user) return this.$store.getters.user
    return {}
    }
  },
  methods: {
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    savePassword() {
      this.$store
          .dispatch("updatePassowrdUser", this.form);
    },
    deleteUser(item) {
      this.$alert
        .fireConfirm("Eliminar Mi Cuenta", "Esta seguro que desea continuar?")
        .then(() => {
          this.showModal = true;
          this.selItem = item;
        });
    },
  },
};
</script>
